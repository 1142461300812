import React from 'react';
import axios from 'axios';
import { Form, Field } from 'q3-ui-forms/src/builders';
import { Box, Typography } from '@material-ui/core';
import FormContainer from '../components/FormContainer';
import SEOHelmet from '../components/SEOHelmet';
import { handleCapt } from '../components/Recaptcha';

const Recovery = () => {
  const [success, setSuccess] = React.useState();

  const handleSubmit = handleCapt((values) =>
    axios.post('/account-recovery', values).then((res) => {
      setSuccess(true);
      return res;
    }),
  );

  const genFieldProps = (props) => ({
    type: 'text',
    required: true,
    xl: 12,
    lg: 12,
    ...props,
  });

  return (
    <FormContainer>
      <SEOHelmet title="Account Recovery" />
      <Box mb={1}>
        <Typography variant="h1">
          Account Recovery
        </Typography>
        {success ? (
          <Box mt={1}>
            <Typography variant="body1">
              A Gentek staff member will reach out to you as
              soon as possible to help recover your online
              account.
            </Typography>
          </Box>
        ) : (
          <Box mt={1}>
            <Typography variant="body1">
              Unsure the credentials to your account? No
              problem, we're here to help.
            </Typography>
            <Form onSubmit={handleSubmit}>
              {[
                { name: 'name' },
                { name: 'username' },
                { name: 'company' },
                {
                  name: 'email',
                  type: 'email',
                  label:
                    'Email address we can reach you at',
                },
                { name: 'tel', type: 'tel' },
                { name: 'postal', type: 'postal' },
              ].map((props) => (
                <Field
                  key={props.name}
                  {...genFieldProps(props)}
                />
              ))}
            </Form>
          </Box>
        )}
      </Box>
    </FormContainer>
  );
};

export default Recovery;
